import { createSlice } from "@reduxjs/toolkit";
import { setError } from "../../core/features/app/appSlice";
import i18n from "../../core/i18n";

import {
  PROD_SPACE_ID,
  UAT_SPACE_ID,
  PC_CREATOR_APP_ID,
  DAYS_IBEX_IDS,
  ENTITLEMENT_IDS
} from "../../utils/constants";

import { fetchInventory } from "@mooncake/mooncake-gateway-ecom/events/ubiservices/secondaryStore";
import { fetchUser } from "@mooncake/mooncake-gateway-ecom/events/ubiservices/users";
import {
  fetchAccount,
  fetchSubscriptionBillings
} from "@mooncake/mooncake-gateway-ecom/events/subscription";
import { fetchUssSubscriptionsV2 } from "@mooncake/mooncake-gateway-ecom/events/ussSubscriptions";
import EcomAPI from "@mooncake/mooncake-gateway-ecom";

const userSlice = createSlice({
  name: "user",
  initialState: {
    connectedUser: {
      authenticated: false,
      username: "",
      inventory: [],
      billings: [],
      subscription: null,
      subscriptionProducts: [],
      currentSubscription: null
    }
  },
  reducers: {
    setAuthenticated(state, action) {
      state.connectedUser.authenticated = action.payload;
    },
    setBillings(state, action) {
      state.connectedUser.billings = action.payload;
    },
    setCurrentSubscription(state, action) {
      state.connectedUser.currentSubscription = action.payload;
    },
    setInventory(state, action) {
      state.connectedUser.inventory = action.payload;
    },
    setSubscription(state, action) {
      state.connectedUser.subscription = action.payload;
    },
    setUsername(state, action) {
      state.connectedUser.username = action.payload;
    },
    setSubscriptionProducts(state, action) {
      state.connectedUser.subscriptionProducts = action.payload;
    }
  }
});

export const fetchUserInventory = (locale, env) => dispatch => {
  return new Promise((resolve, reject) => {
    fetchInventory(
      {
        spaceId:
          env === EcomAPI.ENVIRONMENTS.PROD ? PROD_SPACE_ID : UAT_SPACE_ID,
        locale
      },
      response => {
        if (!response.error) {
          const filteredItems = response.resource.items.filter(
            item =>
              DAYS_IBEX_IDS.includes(item.itemId) ||
              ENTITLEMENT_IDS.includes(item.itemId)
          );
          dispatch(setInventory(filteredItems));
          resolve(response.resource);
        } else {
          console.error(response.error);
          dispatch(setError(i18n.t("errors.fetchSecondaryStoreInventory")));
          reject(response.error);
        }
      }
    )();
  });
};

export const fetchUserProfile = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetchUser(response => {
      if (!response.error) {
        dispatch(setAuthenticated(true));
        dispatch(setUsername(response.resource.nameOnPlatform));
        resolve(response.resource);
      } else {
        console.error(response.error);
        dispatch(setError(i18n.t("errors.fetchUserProfile")));
        reject(response.error);
      }
    })();
  });
};

export const fetchAllSubscriptions = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetchUssSubscriptionsV2(
      {
        additionalParams: "and type eq 'ibex' &extraFields[]=products"
      },
      async response => {
        if (!response.error) {
          const subscriptions = response.resource.data.sort((a, b) =>
            b.startedAt.localeCompare(a.startedAt)
          );
          const activeSubscriptions = response.resource.data.filter(
            subscription =>
              subscription.status === "active" ||
              subscription.status === "suspended"
          );
          const pcSubscription = activeSubscriptions.find(
            subscription => subscription.creatorAppId === PC_CREATOR_APP_ID
          );
          if (pcSubscription) {
            dispatch(setSubscription(pcSubscription));
          } else if (activeSubscriptions.length > 0) {
            dispatch(setSubscription(activeSubscriptions[0]));
          } else {
            dispatch(setSubscription(subscriptions[0]));
          }
          let productsList = [];
          subscriptions.forEach(subscription => {
            if (subscription.products.length) {
              productsList = productsList.concat(subscription.products);
            }
          });
          dispatch(setSubscriptionProducts(productsList));
          resolve(response.resource);
        } else {
          console.error(response.error);
          dispatch(setError(i18n.t("errors.fetchUserSubscription")));
          reject(response.error);
        }
      }
    )();
  });
};

export const fetchUserCurrentSubscription = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetchAccount(
      {
        fields: ["currentSubscription"],
        additionalParams: {
          subscription_type: "ibex"
        }
      },
      response => {
        if (!response.error) {
          const { currentSubscription } = response.resource;
          dispatch(setCurrentSubscription(currentSubscription));
          if (currentSubscription && currentSubscription.status === "active") {
            fetchSubscriptionBillings(
              {
                subscriptionId: currentSubscription.id
              },
              responseBillings => {
                if (!response.error) {
                  dispatch(setBillings(responseBillings.resource));
                  resolve(true);
                } else {
                  console.error(responseBillings.error);
                  dispatch(
                    setError(i18n.t("errors.fetchSubscriptionBillings"))
                  );
                  reject(responseBillings.error);
                }
              }
            )();
          } else {
            resolve(response.resource);
          }
        } else {
          console.error(response.error);
          dispatch(setError(i18n.t("errors.fetchUserSubscription")));
          reject(response.error);
        }
      }
    )();
  });
};

// eslint-disable-next-line no-empty-pattern
export const {
  setAuthenticated,
  setBillings,
  setCurrentSubscription,
  setInventory,
  setSubscription,
  setSubscriptionProducts,
  setUsername
} = userSlice.actions;

export default userSlice.reducer;
