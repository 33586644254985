import kebabCase from "lodash/kebabCase";
import * as React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { StyleSheetManager } from "styled-components";

import packageJson from "../../package.json";
import App, { AppPropTypes } from "./App";

const extraSelectorPlugin = scope => {
  const plugin = (context, content, selectors) => {
    if (context === 2) {
      selectors.forEach((selector, index) => {
        if (selector.indexOf(scope) < 0) {
          selectors[index] = selector.replace(
            /^(.+?)((?:[ :]|$)+.*$)/i,
            `$1${scope}$2`
          );
        }
      });
    }
  };

  Object.defineProperty(plugin, "name", { value: "extraSelectorPlugin" });

  return plugin;
};

class MicroElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.observer = new MutationObserver(() => this.update());
    this.observer.observe(this, { attributes: true });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = this.innerHTML;
    this.mount();
  }

  disconnectedCallback() {
    this.unmount();
    this.observer.disconnect();
  }

  update() {
    this.unmount();
    this.mount();
  }

  mount() {
    const propTypes = AppPropTypes ? AppPropTypes : {};
    const events = AppPropTypes ? AppPropTypes : {};

    const props = {
      ...this.getProps(propTypes),
      ...this.getEvents(events)
    };

    const stylisPlugins = window.ShadyCSS
      ? [extraSelectorPlugin(`.style-scope.${kebabCase(packageJson.name)}`)]
      : [];

    const appContainer = (
      <StyleSheetManager target={this.shadowRoot} stylisPlugins={stylisPlugins}>
        <App {...props} />
      </StyleSheetManager>
    );

    if (process.env.NODE_ENV !== "production") {
      // ========================================================
      // DEVELOPMENT STAGE! HOT MODULE REPLACE ACTIVATION!
      // ========================================================
      const devRender = () => {
        if (module.hot) {
          module.hot.accept("./App", () => {
            render(appContainer, this.shadowRoot);
          });
        }
        render(appContainer, this.shadowRoot);
      };

      // Wrap render in try/catch
      try {
        devRender();
      } catch (error) {
        console.error(error);
      }
    } else {
      // ========================================================
      // PRODUCTION GO!
      // ========================================================
      render(appContainer, this.shadowRoot);
    }
  }

  unmount() {
    unmountComponentAtNode(this);
  }

  getProps(propTypes = {}) {
    return Array.prototype.slice
      .call(this.attributes)
      .filter(attr => attr.name !== "style")
      .map(attr => this.convert(propTypes, attr.name, attr.value))
      .reduce(
        (props, prop) => ({
          ...props,
          [prop.name]: prop.value
        }),
        {}
      );
  }

  getEvents(propTypes) {
    return Object.keys(propTypes)
      .filter(key => /^on([A-Z].*)/.exec(key))
      .reduce(
        (events, ev) => ({
          ...events,
          [ev]: args => {
            const evt = document.createEvent("CustomEvent");
            evt.initCustomEvent(ev, false, false, args);
            this.dispatchEvent(evt);
          }
        }),
        {}
      );
  }

  convert(propTypes, attrName, attrValue) {
    const propNames = Object.keys(propTypes).filter(
      key => key.toLowerCase() === attrName
    );
    const propName = propNames.length ? propNames[0] : attrName;
    let value = attrValue;

    if (attrValue === "true" || attrValue === "false") {
      value = attrValue === "true";
    } else if (!isNaN(attrValue) && attrValue !== "") {
      value = +attrValue;
    } else if (/^[[{].*[}\]]$/.exec(attrValue)) {
      value = JSON.parse(attrValue);
    }

    return {
      name: propName,
      value
    };
  }
}

export default MicroElement;
