import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, useTheme } from "@mooncake/ui";
import { fetchStart, fetchEnd } from "../../core/features/app/appSlice";
import EcomAPI from "@mooncake/mooncake-gateway-ecom";

import {
  fetchAllSubscriptions,
  fetchUserCurrentSubscription,
  fetchUserInventory,
  fetchUserProfile
} from "../../features/user/userSlice";
import NotLoggedIn from "../Blocks/NotLogged";

import Header from "../Sections/Header";
import History from "../Blocks/History";
import Subscriptions from "../Blocks/Subscriptions";
import JoinNow from "../Blocks/JoinNow";
import { sendAdobeEvent } from "../../utils";

const Content = ({
  authenticated,
  connectedUser,
  fetchEnd,
  fetchStart,
  fetchAllSubscriptions,
  fetchUserCurrentSubscription,
  fetchUserInventory,
  fetchUserProfile,
  loading,
  locale,
  transactionslink,
  env
}) => {
  useEffect(() => {
    if (authenticated) {
      EcomAPI.environment =
        env && EcomAPI.ENVIRONMENTS[env.toUpperCase()]
          ? EcomAPI.ENVIRONMENTS[env.toUpperCase()]
          : EcomAPI.ENVIRONMENTS.UAT;
      EcomAPI.appId = "21a6b8b8-0e1a-4308-b2fc-bfd3470bd4d6";

      const userSubscriptions = fetchAllSubscriptions();
      const userCurrentSubscription = fetchUserCurrentSubscription();
      const userInventory = fetchUserInventory(locale, env.toLowerCase());
      const userProfile = fetchUserProfile();

      Promise.all([
        userSubscriptions,
        userCurrentSubscription,
        userInventory,
        userProfile
      ])
        .then(() => {
          fetchEnd();
        })
        .catch(() => {
          fetchEnd();
        });
    }
  }, [
    authenticated,
    fetchStart,
    fetchEnd,
    fetchAllSubscriptions,
    fetchUserCurrentSubscription,
    fetchUserInventory,
    fetchUserProfile,
    locale,
    env
  ]);

  useEffect(() => {
    if (!loading) {
      const noSubscription =
        connectedUser &&
        connectedUser.inventory.length === 0 &&
        !connectedUser.subscription &&
        !connectedUser.currentSubscription;

      let pageName = "";
      if (noSubscription) {
        pageName = "no subscription";
      } else if (connectedUser.currentSubscription) {
        if (connectedUser.currentSubscription.status === "active") {
          pageName = "active subscription";
        } else {
          pageName = "inactive subscription";
        }
      }
      sendAdobeEvent(
        {
          pageName,
          pageUrl: window.location.href,
          siteSection: "your subscription",
          mdmBrandId: 934,
          mdmInstallmentId: 5257,
          installmentName: "rocksmith plus",
          brandName: "rocksmith",
          ubisoftId: window.ubi_uid
        },
        "pageevent"
      );
    }
  }, [connectedUser, loading]);

  const theme = useTheme();
  const noSubscription =
    connectedUser &&
    connectedUser.inventory.length === 0 &&
    !connectedUser.subscription &&
    !connectedUser.currentSubscription;

  return (
    !loading && (
      <Container
        maxWidth={"1200px"}
        margin={"auto"}
        padding={{
          default: "1.25rem",
          tablet: "3.125rem"
        }}
        backgroundColor={theme.colors.background.primary}
      >
        {connectedUser.authenticated ? (
          <React.Fragment>
            <Header noSubscription={noSubscription} />
            {!noSubscription ? (
              <Container
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                alignItems={"stretch"}
                marginTop={"1.25rem"}
              >
                <Subscriptions />
                <History locale={locale} transactionslink={transactionslink} />
              </Container>
            ) : (
              <JoinNow />
            )}
          </React.Fragment>
        ) : (
          <NotLoggedIn />
        )}
      </Container>
    )
  );
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  initialized: state.app.initialized,
  locale: state.app.locale,
  connectedUser: state.user.connectedUser
});

const mapDispatchToProps = {
  fetchStart,
  fetchEnd,
  fetchAllSubscriptions,
  fetchUserCurrentSubscription,
  fetchUserInventory,
  fetchUserProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);
