export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "你的訂閱現正使用中"
      },
      youCantPlay: {
        title: "你的訂閱目前停用",
        description: "若要再次開始遊玩，你可以在下方重新啟用你的訂閱。"
      },
      youDontHaveASubscription: {
        title: "你目前沒有任何訂閱方案",
        joinNow: {
          title: "立即加入體驗",
          subscribe: "訂閱"
        }
      }
    },
    yourSubscriptions: {
      title: "你的訂閱",
      description: "在此查看你目前使用中和過往的《Rocksmith+》訂閱。",
      nextBillingDate: "下次計費日期：",
      manage: "管理",
      cancel: "取消",
      userStatus: {
        active: "使用中",
        inactive: "停用",
        suspended: "停用"
      }
    },
    subscriptionHistory: {
      title: "訂閱歷史記錄",
      date: "日期",
      viewMore: "查看更多"
    },
    notLoggedIn: {
      title: "未經授權",
      message: "你必須登入才能存取這個頁面！"
    },
    ghostItems: {
      "PC-Monthly": "訂購服務 1 個月（PC）",
      "PC-3Months": "訂購服務 3 個月（PC）",
      "PC-6Months": "訂購服務 6 個月（PC）",
      "PC-Annually": "訂購服務 12 個月（PC）",
      "PS-Monthly": "1 個月訂閱（Playstation）",
      "PS-3Months": "3 個月訂閱（Playstation）",
      "PS-6Months": "6 個月訂閱（Playstation）",
      "PS-Annually": "12 個月訂閱（Playstation）",
      "XBOX-Monthly": "1 個月訂閱（XBOX）",
      "XBOX-3Months": "3 個月訂閱（XBOX）",
      "XBOX-6Months": "6 個月訂閱（XBOX）",
      "XBOX-Annually": "12 個月訂閱（XBOX）",
      "iOS-Monthly": "1 個月訂閱（iOS）",
      "iOS-3Months": "3 個月訂閱（iOS）",
      "iOS-6Months": "6 個月訂閱（iOS）",
      "iOS-Annually": "12 個月訂閱（iOS）",
      "ANDROID-Monthly": "1 個月訂閱（Android）",
      "ANDROID-3Months": "3 個月訂閱（Android）",
      "ANDROID-6Months": "6 個月訂閱（Android）",
      "ANDROID-Annually": "12 個月訂閱（Android）"
    }
  }
};
