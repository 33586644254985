export const UAT_SPACE_ID = "11699c1a-ff78-4155-84fb-7afc5d911bc1";
export const PROD_SPACE_ID = "d54324b5-e7d9-49f2-b60f-beb7e8064666";

export const DAYS_IBEX_IDS = [
  "7455a392-923e-44ee-a673-7c1caa127f86", // PC, iOS and Android
  "a0f6b76d-e247-4657-84c3-ad51406e4e9d" // Test
];

export const ENTITLEMENT_IDS = [
  "ee9e7e4e-084f-42f7-9008-5494b2e53ca2", // PC
  "591c3b01-4487-4fe2-a7d4-5641169a4ba0", // PC Test
  "6b8a8a8b-6907-4a15-a465-ed22fd4780dd", // iOS
  "6c894963-48a7-482c-a554-50d1eadeb2f5", // iOS Test
  "25bf5faa-a07b-4de3-94cb-e58a71765f46", // Android
  "12638239-d64a-4007-98c1-226366bea14b" // Android Test
];

export const ANDROID_CREATOR_APP_ID = "3a75b085-81de-4511-849d-15a14fe0c370";
export const IOS_CREATOR_APP_ID = "70ca8722-069c-46ee-a0bf-c687040ad928";
export const PC_CREATOR_APP_ID = "bfea1093-0f11-409a-95d3-cb107e5beda3";
export const USS_CREATOR_APP_ID = "907878f1-868a-4001-913d-de6c76a08cb0";
export const CODE_REDEEM_CREATOR_APP_ID =
  "24cf928a-8912-4149-b513-2b69b376a2bf";

export const MANAGE_SUBSCRIPTION_URLS = storeURL => [
  {
    platform: "PC",
    url: `${storeURL}/my-account`
  },
  {
    platform: "Playstation",
    url: "https://store.playstation.com/subscriptions"
  }
];

export const CANCEL_PC_SUBSCRIPTION_URL = storeURL =>
  `${storeURL}/subscription-cancellation?subscription=rocksmithplus`;
