export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Tilauksesi on aktiivinen"
      },
      youCantPlay: {
        title: "Tilauksesi ei ole aktiivinen",
        description:
          "Aktivoi tilauksesi uudelleen, niin voit jatkaa pelaamista."
      },
      youDontHaveASubscription: {
        title: "Sinulla ei ole vielä tilausta",
        joinNow: {
          title: "Liity nyt ja nauti kokemuksesta",
          subscribe: "Tilaa"
        }
      }
    },
    yourSubscriptions: {
      title: "Tilauksesi",
      description:
        "Tarkastele aktiivisia ja aiempia Rocksmith+-tilauksiasi tässä.",
      nextBillingDate: "Seuraava laskutuspäivä:",
      manage: "Hallitse",
      cancel: "Peruuttaa",
      userStatus: {
        active: "Aktiivinen",
        inactive: "Ei aktiivinen",
        suspended: "Ei aktiivinen"
      }
    },
    subscriptionHistory: {
      title: "Subscription History",
      date: "Päivämäärä",
      viewMore: "Näytä lisää"
    },
    notLoggedIn: {
      title: "Pääsy evätty",
      message:
        "Sinun on kirjauduttava sisään, jotta voit siirtyä tälle sivulle!"
    },
    ghostItems: {
      "PC-Monthly": "1 kuukauden tilaus (PC)",
      "PC-3Months": "3 kuukauden tilaus (PC)",
      "PC-6Months": "6 kuukauden tilaus (PC)",
      "PC-Annually": "12 kuukauden tilaus (PC)",
      "PS-Monthly": "1 kuukauden tilaus (Playstation)",
      "PS-3Months": "3 kuukauden tilaus (Playstation)",
      "PS-6Months": "6 kuukauden tilaus (Playstation)",
      "PS-Annually": "12 kuukauden tilaus (Playstation)",
      "XBOX-Monthly": "1 kuukauden tilaus (XBOX)",
      "XBOX-3Months": "3 kuukauden tilaus (XBOX)",
      "XBOX-6Months": "6 kuukauden tilaus (XBOX)",
      "XBOX-Annually": "12 kuukauden tilaus (XBOX)",
      "iOS-Monthly": "1 kuukauden tilaus (iOS)",
      "iOS-3Months": "3 kuukauden tilaus (iOS)",
      "iOS-6Months": "6 kuukauden tilaus (iOS)",
      "iOS-Annually": "12 kuukauden tilaus (iOS)",
      "ANDROID-Monthly": "1 kuukauden tilaus (Android)",
      "ANDROID-3Months": "3 kuukauden tilaus (Android)",
      "ANDROID-6Months": "6 kuukauden tilaus (Android)",
      "ANDROID-Annually": "12 kuukauden tilaus (Android)"
    }
  }
};
