export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Sua assinatura está ativa"
      },
      youCantPlay: {
        title: "Sua assinatura está inativa",
        description:
          "Para começar a jogar de novo, é possível reativar sua assinatura abaixo."
      },
      youDontHaveASubscription: {
        title: "Você ainda não possui uma assinatura",
        joinNow: {
          title: "Participe da experiência agora",
          subscribe: "Assinar"
        }
      }
    },
    yourSubscriptions: {
      title: "Suas assinaturas",
      description: "Veja suas assinaturas antigas e ativa de Rocksmith+ aqui.",
      nextBillingDate: "Data da próxima cobrança:",
      manage: "Gerenciar",
      cancel: "Cancelar",
      userStatus: {
        active: "Ativa",
        inactive: "Inativa",
        suspended: "Inativa"
      }
    },
    subscriptionHistory: {
      title: "Histórico de Assinaturas",
      date: "Data",
      viewMore: "Ver mais"
    },
    notLoggedIn: {
      title: "Não autorizado",
      message: "Você precisa se conectar para acessar essa página!"
    },
    ghostItems: {
      "PC-Monthly": "Assinatura de 1 mês (PC)",
      "PC-3Months": "Assinatura de 3 mês (PC)",
      "PC-6Months": "Assinatura de 6 mês (PC)",
      "PC-Annually": "Assinatura de 12 mês (PC)",
      "PS-Monthly": "Assinatura de 1 mês (PlayStation)",
      "PS-3Months": "Assinatura de 3 meses (PlayStation)",
      "PS-6Months": "Assinatura de 6 meses (PlayStation)",
      "PS-Annually": "Assinatura de 12 meses (PlayStation)",
      "XBOX-Monthly": "Assinatura de 1 mês (XBOX)",
      "XBOX-3Months": "Assinatura de 3 meses (XBOX)",
      "XBOX-6Months": "Assinatura de 6 meses (XBOX)",
      "XBOX-Annually": "Assinatura de 12 meses (XBOX)",
      "iOS-Monthly": "Assinatura de 1 mês (iOS)",
      "iOS-3Months": "Assinatura de 3 meses (iOS)",
      "iOS-6Months": "Assinatura de 6 meses (iOS)",
      "iOS-Annually": "Assinatura de 12 meses (iOS)",
      "ANDROID-Monthly": "Assinatura de 1 mês (Android)",
      "ANDROID-3Months": "Assinatura de 3 meses (Android)",
      "ANDROID-6Months": "Assinatura de 6 meses (Android)",
      "ANDROID-Annually": "Assinatura de 12 meses (Android)"
    }
  }
};
