import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import moment from "moment";

import Separator from "../../Elements/Separator";
import { Container, Icon, Text } from "@mooncake/ui";
import { IbexCheck, IbexCross, IbexQuestionMark } from "@mooncake/ui/lib/icons";

const Header = ({ connectedUser, noSubscription, t, theme }) => {
  const canPlay =
    connectedUser.inventory.length > 0 &&
    connectedUser.inventory.some(
      subscription =>
        subscription.expirationDate === null ||
        moment() > moment(subscription.expirationDate)
    );

  return (
    connectedUser && (
      <Container textAlign={"center"}>
        <Text.Heading bold primary margin={"0"}>
          {connectedUser.username}
        </Text.Heading>
        <Separator backgroundColor={theme.colors.background.primary} />
        <Container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text.Heading bold primary margin={"0"}>
            {noSubscription
              ? t("profileStatus.youDontHaveASubscription.title")
              : canPlay
              ? t("profileStatus.youCanPlay.title")
              : t("profileStatus.youCantPlay.title")}
          </Text.Heading>
          <Container marginLeft={"1rem"}>
            <Icon
              icon={
                noSubscription
                  ? IbexQuestionMark
                  : canPlay
                  ? IbexCheck
                  : IbexCross
              }
              size={"2x"}
              color={
                canPlay
                  ? theme.colors.secondary.normal
                  : !noSubscription
                  ? theme.colors.error
                  : null
              }
            />
          </Container>
        </Container>
      </Container>
    )
  );
};

const mapStateToProps = state => ({
  connectedUser: state.user.connectedUser
});

export default connect(mapStateToProps)(withTranslation()(withTheme(Header)));
