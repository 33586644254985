export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "サブスクリプションは有効です"
      },
      youCantPlay: {
        title: "サブスクリプションは無効です",
        description:
          "プレイを再開するには以下よりサブスクリプションを有効にしてください"
      },
      youDontHaveASubscription: {
        title: "現在サブスクリプションはありません",
        joinNow: {
          title: "今すぐ体験しましょう",
          subscribe: "サブスクライブする"
        }
      }
    },
    yourSubscriptions: {
      title: "お客様のサブスクリプション",
      description:
        "現在の状況と過去のRocksmith+サブスクリプションをこちらでご覧いただけます。",
      nextBillingDate: "次回ご請求日：",
      manage: "管理",
      cancel: "キャンセル",
      userStatus: {
        active: "ご利用中",
        inactive: "無効",
        suspended: "無効"
      }
    },
    subscriptionHistory: {
      title: "サブスクリプション履歴",
      date: "日付",
      viewMore: "詳細"
    },
    notLoggedIn: {
      title: "認証されていません",
      message: "本ページへのアクセスにはログインが必要です！"
    },
    ghostItems: {
      "PC-Monthly": "1ヶ月のサブスクリプション (PC)",
      "PC-3Months": "3ヶ月のサブスクリプション (PC)",
      "PC-6Months": "6ヶ月のサブスクリプション (PC)",
      "PC-Annually": "12ヶ月のサブスクリプション (PC)",
      "PS-Monthly": "1 カ月 サブスクリプション (PlayStation)",
      "PS-3Months": "3 カ月 サブスクリプション (PlayStation)",
      "PS-6Months": "6 カ月 サブスクリプション (PlayStation)",
      "PS-Annually": "12 カ月 サブスクリプション (PlayStation)",
      "XBOX-Monthly": "1 カ月 サブスクリプション (XBOX)",
      "XBOX-3Months": "3 カ月 サブスクリプション (XBOX)",
      "XBOX-6Months": "6 カ月 サブスクリプション (XBOX)",
      "XBOX-Annually": "12 カ月 サブスクリプション (XBOX)",
      "iOS-Monthly": "1 カ月 サブスクリプション (iOS)",
      "iOS-3Months": "3 カ月 サブスクリプション (iOS)",
      "iOS-6Months": "6 カ月 サブスクリプション (iOS)",
      "iOS-Annually": "12 カ月 サブスクリプション (iOS)",
      "ANDROID-Monthly": "1 カ月 サブスクリプション (Android)",
      "ANDROID-3Months": "3 カ月 サブスクリプション (Android)",
      "ANDROID-6Months": "6 カ月 サブスクリプション (Android)",
      "ANDROID-Annually": "12 カ月 サブスクリプション (Android)"
    }
  }
};
