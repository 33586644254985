export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Dit abonnement er aktivt"
      },
      youCantPlay: {
        title: "Dit abonnement er inaktivt",
        description:
          "Du kan genaktivere dit abonnement herunder, hvis du vil spille igen."
      },
      youDontHaveASubscription: {
        title: "Du har ikke et abonnement endnu",
        joinNow: {
          title: "Deltag i oplevelsen nu",
          subscribe: "Abonnér"
        }
      }
    },
    yourSubscriptions: {
      title: "Dine abonnementer",
      description: "Se dine aktive og tidligere Rocksmith+-abonnementer her.",
      nextBillingDate: "Næste betalingsdato:",
      manage: "Administrér",
      cancel: "Afbestille",
      userStatus: {
        active: "Aktivt",
        inactive: "Inaktivt",
        suspended: "Inaktivt"
      }
    },
    subscriptionHistory: {
      title: "Subscription History",
      date: "Dato",
      viewMore: "Vis mere"
    },
    notLoggedIn: {
      title: "Uautoriseret",
      message: "Du skal være logget ind for at have adgang til denne side!"
    },
    ghostItems: {
      "PC-Monthly": "1 måneds abonnement (pc)",
      "PC-3Months": "3 måneders abonnement (pc)",
      "PC-6Months": "6 måneders abonnement (pc)",
      "PC-Annually": "12 måneders abonnement (pc)",
      "PS-Monthly": "1 måneds abonnement (Playstation)",
      "PS-3Months": "3 måneders abonnement (Playstation)",
      "PS-6Months": "6 måneders abonnement (Playstation)",
      "PS-Annually": "12 måneders abonnement (Playstation)",
      "XBOX-Monthly": "1 måneds abonnement (XBOX)",
      "XBOX-3Months": "3 måneders abonnement (XBOX)",
      "XBOX-6Months": "6 måneders abonnement (XBOX)",
      "XBOX-Annually": "12 måneders abonnement (XBOX)",
      "iOS-Monthly": "1 måneds abonnement (iOS)",
      "iOS-3Months": "3 måneders abonnement (iOS)",
      "iOS-6Months": "6 måneders abonnement (iOS)",
      "iOS-Annually": "12 måneders abonnement (iOS)",
      "ANDROID-Monthly": "1 måneds abonnement (iOS)",
      "ANDROID-3Months": "3 måneders abonnement (iOS)",
      "ANDROID-6Months": "6 måneders abonnement (iOS)",
      "ANDROID-Annually": "12 måneders abonnement (iOS)"
    }
  }
};
