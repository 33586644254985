export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Dein Abonnement ist aktiv"
      },
      youCantPlay: {
        title: "Dein Abonnement ist inaktiv",
        description:
          "Wenn du erneut spielen möchtest, kannst du dein Abonnement hierunter reaktivieren."
      },
      youDontHaveASubscription: {
        title: "Du hast noch kein Abonnement",
        joinNow: {
          title: "Schließ dich jetzt dem Erlebnis an",
          subscribe: "Abonnieren"
        }
      }
    },
    yourSubscriptions: {
      title: "Deine Abonnements",
      description:
        "Schau dir hier deine aktiven und vergangenen Rocksmith+ Abonnements an.",
      nextBillingDate: "Nächstes Abrechnungsdatum",
      manage: "Verwalten",
      cancel: "Kündigen",
      userStatus: {
        active: "Aktiv",
        inactive: "Inaktiv",
        suspended: "Inaktiv"
      }
    },
    subscriptionHistory: {
      title: "Abonnement-Verlauf",
      date: "Datum",
      viewMore: "Mehr anzeigen"
    },
    notLoggedIn: {
      title: "Nicht erlaubt",
      message: "Du musst dich erst anmelden, um auf diese Seite zuzugreifen!"
    },
    ghostItems: {
      "PC-Monthly": "Abonnement 1 Monat (PC)",
      "PC-3Months": "Abonnement 3 Monate (PC)",
      "PC-6Months": "Abonnement 6 Monate (PC)",
      "PC-Annually": "Abonnement 12 Monate (PC)",
      "PS-Monthly": "Abonnement für 1 Monat (Playstation)",
      "PS-3Months": "Abonnement für 3 Monate (Playstation)",
      "PS-6Months": "Abonnement für 6 Monate (Playstation)",
      "PS-Annually": "Abonnement für 12 Monate (Playstation)",
      "XBOX-Monthly": "Abonnement für 1 Monat (XBOX)",
      "XBOX-3Months": "Abonnement für 3 Monate (XBOX)",
      "XBOX-6Months": "Abonnement für 6 Monate (XBOX)",
      "XBOX-Annually": "Abonnement für 12 Monate (XBOX)",
      "iOS-Monthly": "Abonnement für 1 Monat (iOS)",
      "iOS-3Months": "Abonnement für 3 Monate (iOS)",
      "iOS-6Months": "Abonnement für 6 Monate (iOS)",
      "iOS-Annually": "Abonnement für 12 Monate (iOS)",
      "ANDROID-Monthly": "Abonnement für 1 Monat (Android)",
      "ANDROID-3Months": "Abonnement für 3 Monate (Android)",
      "ANDROID-6Months": "Abonnement für 6 Monate (Android)",
      "ANDROID-Annually": "Abonnement für 12 Monate (Android)"
    }
  }
};
