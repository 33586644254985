import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import { Container, Text } from "@mooncake/ui";
import SubscriptionItem from "../../Elements/SubscriptionItem";
import Separator from "../../Elements/Separator";
import ManageActions from "../../Elements/ManageActions";

const Subscription = ({ connectedUser, t, theme }) => {
  const { billings, subscription } = connectedUser;
  return (
    <Container
      backgroundColor={theme.colors.background.secondary}
      textAlign={"center"}
      flex={{
        default: "0 0 100%",
        tabletLarge: "0 0 48%"
      }}
      marginBottom={{
        default: "1.875rem",
        tablet: "2rem",
        tabletLarge: "0"
      }}
      maxWidth={{
        tabletLarge: "48%"
      }}
    >
      <Container
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        minHeight={"100%"}
        padding={{
          default: "1.25rem",
          tablet: "1.375rem 1.875rem"
        }}
      >
        <Text.Heading bold secondary>
          {t("yourSubscriptions.title")}
        </Text.Heading>
        <Container
          maxWidth={{
            default: "100%",
            tablet: "90%"
          }}
        >
          <Text.Paragraph
            primary
            color={theme.colors.text.secondary}
            margin={"0"}
          >
            {t("yourSubscriptions.description")}
          </Text.Paragraph>
        </Container>
        <Container
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
          alignItems={"center"}
          flex={"1"}
          width={"100%"}
        >
          <Separator backgroundColor={theme.colors.background.secondary} />
          <SubscriptionItem subscription={subscription} billings={billings} />
        </Container>
        <ManageActions subscription={subscription} />
      </Container>
    </Container>
  );
};

const mapStateToProps = state => ({
  connectedUser: state.user.connectedUser
});

export default connect(mapStateToProps)(
  withTranslation()(withTheme(Subscription))
);
