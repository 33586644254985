import React from "react";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import { Container, Text } from "@mooncake/ui";
import Separator from "../../Elements/Separator";

const NotLoggedIn = ({ t, theme }) => {
  return (
    <Container textAlign={"center"}>
      <Text.Heading bold primary margin={"0"}>{t("notLoggedIn.title")}</Text.Heading>
      <Separator backgroundColor={theme.colors.background.primary} />
      <Text.Paragraph dangerouslySetInnerHTML={{ __html: t("notLoggedIn.message") }} />
    </Container>
  );
};

export default withTranslation()(withTheme(NotLoggedIn));
