export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Votre abonnement est actif"
      },
      youCantPlay: {
        title: "Votre abonnement est inactif",
        description:
          "Pour recommencer à jouer, vous pouvez réactiver votre abonnement ci-dessous."
      },
      youDontHaveASubscription: {
        title: "Vous n'avez pas encore d'abonnement",
        joinNow: {
          title: "Faites l'expérience dès maintenant",
          subscribe: "Je m'abonne"
        }
      }
    },
    yourSubscriptions: {
      title: "Vos abonnements",
      description:
        "Vous trouverez ici vos abonnements Rocksmith+ actifs ou clôturés.",
      nextBillingDate: "Prochaine facturation&nbsp;:",
      manage: "Gérer",
      cancel: "Annuler",
      userStatus: {
        active: "Actif",
        inactive: "Inactif",
        suspended: "Inactif"
      }
    },
    subscriptionHistory: {
      title: "Historique de souscription",
      date: "Date",
      viewMore: "Afficher plus"
    },
    notLoggedIn: {
      title: "Non autorisé",
      message: "Vous devez vous identifier pour accéder à cette page&nbsp;!"
    },
    ghostItems: {
      "PC-Monthly": "Abonnement de 1 mois (PC)",
      "PC-3Months": "Abonnement de 3 mois (PC)",
      "PC-6Months": "Abonnement de 6 mois (PC)",
      "PC-Annually": "Abonnement de 12 mois (PC)",
      "PS-Monthly": "Abonnement de 1 mois (Playstation)",
      "PS-3Months": "Abonnement de 3 mois (Playstation)",
      "PS-6Months": "Abonnement de 6 mois (Playstation)",
      "PS-Annually": "Abonnement de 12 mois (Playstation)",
      "XBOX-Monthly": "Abonnement de 1 mois (XBOX)",
      "XBOX-3Months": "Abonnement de 3 mois (XBOX)",
      "XBOX-6Months": "Abonnement de 6 mois (XBOX)",
      "XBOX-Annually": "Abonnement de 12 mois (XBOX)",
      "iOS-Monthly": "Abonnement de 1 mois (iOS)",
      "iOS-3Months": "Abonnement de 3 mois (iOS)",
      "iOS-6Months": "Abonnement de 6 mois (iOS)",
      "iOS-Annually": "Abonnement de 12 mois (iOS)",
      "ANDROID-Monthly": "Abonnement de 1 mois (Android)",
      "ANDROID-3Months": "Abonnement de 3 mois (Android)",
      "ANDROID-6Months": "Abonnement de 6 mois (Android)",
      "ANDROID-Annually": "Abonnement de 12 mois (Android)"
    }
  }
};
