export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Tu suscripción está activa"
      },
      youCantPlay: {
        title: "Tu suscripción está inactiva",
        description:
          "Para empezar a jugar de nuevo, puedes reactivar la suscripción a continuación."
      },
      youDontHaveASubscription: {
        title: "Todavía no tienes una suscripción",
        joinNow: {
          title: "Únete ya a la experiencia",
          subscribe: "Suscríbete"
        }
      }
    },
    yourSubscriptions: {
      title: "Tus suscripciones",
      description:
        "Aquí puedes ver tu suscripción activa a Rocksmith+ y las suscripciones pasadas.",
      nextBillingDate: "Siguiente fecha de facturación:",
      manage: "Gestionar",
      cancel: "Cancelar",
      userStatus: {
        active: "Activa",
        inactive: "Inactiva",
        suspended: "Inactiva"
      }
    },
    subscriptionHistory: {
      title: "Historial de Suscripciones",
      date: "Fecha",
      viewMore: "Ver más"
    },
    notLoggedIn: {
      title: "Sin autorización",
      message: "¡Tienes que iniciar sesión para acceder a esta página!"
    },
    ghostItems: {
      "PC-Monthly": "Suscripción de 1 mes (PC)",
      "PC-3Months": "Suscripción de 3 meses (PC)",
      "PC-6Months": "Suscripción de 6 meses (PC)",
      "PC-Annually": "Suscripción de 12 meses (PC)",
      "PS-Monthly": "Suscripción de 1 mes (Playstation)",
      "PS-3Months": "Suscripción de 3 meses (Playstation)",
      "PS-6Months": "Suscripción de 6 meses (Playstation)",
      "PS-Annually": "Suscripción de 12 meses (Playstation)",
      "XBOX-Monthly": "Suscripción de 1 mes (XBOX)",
      "XBOX-3Months": "Suscripción de 3 meses (XBOX)",
      "XBOX-6Months": "Suscripción de 6 meses (XBOX)",
      "XBOX-Annually": "Suscripción de 12 meses (XBOX)",
      "iOS-Monthly": "Suscripción de 1 mes (iOS)",
      "iOS-3Months": "Suscripción de 3 meses (iOS)",
      "iOS-6Months": "Suscripción de 6 meses (iOS)",
      "iOS-Annually": "Suscripción de 12 meses (iOS)",
      "ANDROID-Monthly": "Suscripción de 1 mes (Android)",
      "ANDROID-3Months": "Suscripción de 3 meses (Android)",
      "ANDROID-6Months": "Suscripción de 6 meses (Android)",
      "ANDROID-Annually": "Suscripción de 12 meses (Android)"
    }
  }
};
