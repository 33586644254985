export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Abonnementet ditt er aktivt"
      },
      youCantPlay: {
        title: "Abonnementet ditt er inaktivt",
        description:
          "Reaktiver abonnementet nedenfor for å begynne å spille igjen."
      },
      youDontHaveASubscription: {
        title: "Du har ikke et abonnement ennå",
        joinNow: {
          title: "Bli med nå",
          subscribe: "Abonner"
        }
      }
    },
    yourSubscriptions: {
      title: "Dine abonnementer",
      description: "Se aktive og tidligere Rocksmith+-abonnementer her.",
      nextBillingDate: "Neste faktureringsdato:",
      manage: "Administrer",
      cancel: "Avbryt",
      userStatus: {
        active: "Aktiv",
        inactive: "Inaktiv",
        suspended: "Inaktiv"
      }
    },
    subscriptionHistory: {
      title: "Subscription History",
      date: "Dato",
      viewMore: "Vis mer"
    },
    notLoggedIn: {
      title: "Uautorisert",
      message: "Du må logge inn for å få tilgang til denne siden!"
    },
    ghostItems: {
      "PC-Monthly": "1 måneds abonnement (PC)",
      "PC-3Months": "3 måneders abonnement (PC)",
      "PC-6Months": "6 måneders abonnement (PC)",
      "PC-Annually": "12 måneders abonnement (PC)",
      "PS-Monthly": "1 måneds abonnement (Playstation)",
      "PS-3Months": "3 måneders abonnement (Playstation)",
      "PS-6Months": "6 måneders abonnement (Playstation)",
      "PS-Annually": "12 måneders abonnement (Playstation)",
      "XBOX-Monthly": "1 måneds abonnement (XBOX)",
      "XBOX-3Months": "3 måneders abonnement (XBOX)",
      "XBOX-6Months": "6 måneders abonnement (XBOX)",
      "XBOX-Annually": "12 måneders abonnement (XBOX)",
      "iOS-Monthly": "1 måneds abonnement (iOS)",
      "iOS-3Months": "3 måneders abonnement (iOS)",
      "iOS-6Months": "6 måneders abonnement (iOS)",
      "iOS-Annually": "12 måneders abonnement (iOS)",
      "ANDROID-Monthly": "1 måneds abonnement (Android)",
      "ANDROID-3Months": "3 måneders abonnement (Android)",
      "ANDROID-6Months": "6 måneders abonnement (Android)",
      "ANDROID-Annually": "12 måneders abonnement (Android)"
    }
  }
};
