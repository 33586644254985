import styled from "styled-components";
import { Icon } from "@mooncake/ui";

export const DateContainer = styled.div`
  display: inline-flex;
  padding: 0 0 0.5rem 0;
  border-bottom: 2px solid ${props => props.theme.colors.primary.normal};
  text-transform: uppercase;
  margin-bottom: 1rem;
  letter-spacing: 4px;

  ${Icon} {
    margin-left: 1.5625rem;
  }
`;

export const List = styled.ul`
  width: 100%;
  display: block;
  padding: 0;
  list-style: none;
  margin: 0 0 1.875rem 0;
`;

export const Item = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.secondary};
  line-height: 1rem;
`;
