import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import { getSubPlatform } from "../../../utils";

import { Container, Text } from "@mooncake/ui";

const SubscriptionItem = ({ billings, i18n, subscription, t, theme }) => {
  const rocksmithPlusLogoPath = require("../../../assets/images/logo-rocksmith.svg");
  const platform = getSubPlatform(subscription);

  const subscriptionStatus = i18n.exists(
    `yourSubscriptions.userStatus.${subscription.status}`
  )
    ? t(`yourSubscriptions.userStatus.${subscription.status}`)
    : subscription.status;

  return (
    <Container display={"block"} textAlign={"center"}>
      <Container
        color={theme.colors.text.secondary}
        display={"flex"}
        flexDirection={{
          default: "column",
          tablet: "row"
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Container display={"flex"} alignItems={"center"}>
          <Container>
            <Container display={"flex"} alignItems={"center"}>
              {platform && (
                <Container marginRight={"0.5rem"}>
                  <img
                    src={require(`../../../assets/images/logo-${platform.toLowerCase()}.svg`)}
                    alt={platform}
                    style={{ height: "1.125rem" }}
                  />
                </Container>
              )}
              <Container marginRight={"0.5rem"}>
                <img
                  src={rocksmithPlusLogoPath}
                  alt={"Rocksmith+"}
                  style={{ height: "1rem" }}
                />
              </Container>
              <Container
                color={theme.colors.text.secondary}
                fontSize={{
                  default: "1.2rem",
                  tablet: "1.5rem"
                }}
                margin={"0.5rem"}
              >
                •
              </Container>
              <Text.Paragraph
                bold
                margin={"0 0 0 0.5rem"}
                color={theme.colors.text.primary}
              >
                {subscriptionStatus}
              </Text.Paragraph>
            </Container>
            {billings.length > 0 && platform === "PC" && (
              <Text.Paragraph
                primary
                margin={"0.5rem"}
                dangerouslySetInnerHTML={{
                  __html: `${t("yourSubscriptions.nextBillingDate")} ${moment(
                    billings[0].billingDate
                  )
                    .utc()
                    .format("L")}`
                }}
              />
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default withTranslation()(withTheme(SubscriptionItem));
