export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Your subscription is active"
      },
      youCantPlay: {
        title: "Your subscription is inactive",
        description:
          "To start playing again, you can reactivate your subscription below."
      },
      youDontHaveASubscription: {
        title: "You don't have a subscription",
        joinNow: {
          title: "Join the experience now",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisis, ex in tincidunt egestas, enim dolor convallis quam, vel semper justo risus eu purus.",
          subscribe: "Subscribe"
        }
      }
    },
    yourSubscriptions: {
      title: "Your Subscriptions",
      description: "View your active and past Rocksmith+ subscriptions here.",
      noSubscription: "No subscription for the moment",
      nextBillingDate: "Next Billing Date:",
      manage: "Manage",
      cancel: "Cancel",
      userStatus: {
        active: "Active",
        inactive: "Inactive",
        suspended: "Inactive"
      }
    },
    subscriptionHistory: {
      title: "Subscription History",
      date: "Date",
      viewMore: "View More"
    },
    notLoggedIn: {
      title: "Unauthorized",
      message: "You must be logged in to have access to this page!"
    },
    ghostItems: {
      "PC-Monthly": "1-month subscription (PC)",
      "PC-3Months": "3-month subscription (PC)",
      "PC-6Months": "6-month subscription (PC)",
      "PC-Annually": "12-month subscription (PC)",
      "PS-Monthly": "1-month subscription (Playstation)",
      "PS-3Months": "3-month subscription (Playstation)",
      "PS-6Months": "6-month subscription (Playstation)",
      "PS-Annually": "12-month subscription (Playstation)",
      "XBOX-Monthly": "1-month subscription (XBOX)",
      "XBOX-3Months": "3-month subscription (XBOX)",
      "XBOX-6Months": "6-month subscription (XBOX)",
      "XBOX-Annually": "12-month subscription (XBOX)",
      "iOS-Monthly": "1-month subscription (iOS)",
      "iOS-3Months": "3-month subscription (iOS)",
      "iOS-6Months": "6-month subscription (iOS)",
      "iOS-Annually": "12-month subscription (iOS)",
      "ANDROID-Monthly": "1-month subscription (Android)",
      "ANDROID-3Months": "3-month subscription (Android)",
      "ANDROID-6Months": "6-month subscription (Android)",
      "ANDROID-Annually": "12-month subscription (Android)"
    }
  }
};
