import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { Container, Icon } from "@mooncake/ui";
import { IbexVolume } from "@mooncake/ui/lib/icons";
import { getSubPlatform, sendAdobeEvent } from "../../../utils";
import { Button } from "../Button";
import {
  CANCEL_PC_SUBSCRIPTION_URL,
  MANAGE_SUBSCRIPTION_URLS,
  PC_CREATOR_APP_ID
} from "../../../utils/constants";

const ManageActions = ({ subscription, t, storeURL }) => {
  const platform = getSubPlatform(subscription);
  const manageLink = MANAGE_SUBSCRIPTION_URLS(storeURL).filter(
    url => url.platform === platform
  );
  const cancelLink =
    subscription.creatorAppId === PC_CREATOR_APP_ID &&
    CANCEL_PC_SUBSCRIPTION_URL(storeURL);
  return (
    <Container display={"flex"}>
      {manageLink.length === 1 && (
        <Container padding={"0 5px"}>
          <Button
            forwardedAs={"a"}
            minWidth={!cancelLink ? "11.25rem" : "9rem"}
            href={manageLink[0].url}
            target={"_blank"}
            onClick={() =>
              sendAdobeEvent({
                location: "your subscription",
                category: "action",
                action: "manage"
              })
            }
          >
            <Container marginRight={"auto"}>
              {t("yourSubscriptions.manage")}
            </Container>
            <Icon icon={IbexVolume} color="currentColor" />
          </Button>
        </Container>
      )}
      {cancelLink && subscription.status === "active" && (
        <Container padding={"0 5px"}>
          <Button
            forwardedAs={"a"}
            minWidth={cancelLink ? "9rem" : "11.25rem"}
            href={cancelLink}
            target={"_blank"}
            onClick={() =>
              sendAdobeEvent({
                location: "your subscription",
                category: "action",
                action: "cancel"
              })
            }
          >
            <Container marginRight={"auto"}>
              {t("yourSubscriptions.cancel")}
            </Container>
            <Icon icon={IbexVolume} color="currentColor" />
          </Button>
        </Container>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  storeURL: state.app.storeURL
});

export default connect(mapStateToProps)(withTranslation()(ManageActions));
