import { IbexVolume } from "@mooncake/ui/lib/icons";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTheme } from "styled-components";

import { Container, Icon, Text } from "@mooncake/ui";
import { Button } from "../../Elements/Button";
import Separator from "../../Elements/Separator";
import { sendAdobeEvent } from "../../../utils";

const JoinNow = ({ t, theme, storeURL, ibexProductId }) => {
  const link = `${storeURL}/rocksmith-plus#plans`;
  return (
    <Container
      backgroundColor={theme.colors.background.secondary}
      textAlign={"center"}
      width={{
        default: "100%",
        tabletLarge: "48%"
      }}
      margin={{
        default: "1.875rem auto",
        tablet: "2rem auto",
        tabletLarge: "2rem auto 0"
      }}
      padding={{
        default: "1.25rem",
        tablet: "2rem 2.5rem"
      }}
    >
      <Text.Heading bold secondary>
        {t("profileStatus.youDontHaveASubscription.joinNow.title")}
      </Text.Heading>
      <Container margin={"1.5rem 0"}>
        <Separator backgroundColor={theme.colors.background.secondary} />
      </Container>
      <Button
        forwardedAs={"a"}
        minWidth={"11.25rem"}
        href={link}
        onClick={() =>
          sendAdobeEvent({
            location: "join the experience",
            category: "action",
            action: "subscribe"
          })
        }
      >
        <Container>
          {t("profileStatus.youDontHaveASubscription.joinNow.subscribe")}
        </Container>
        <Icon icon={IbexVolume} color="currentColor" />
      </Button>
    </Container>
  );
};

const mapStateToProps = state => ({
  storeURL: state.app.storeURL,
  ibexProductId: state.app.ibexProductId
});

export default connect(mapStateToProps)(withTranslation()(withTheme(JoinNow)));
