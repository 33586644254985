import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTheme } from "styled-components";

import { Container, Icon, Text } from "@mooncake/ui";
import { IbexVolume } from "@mooncake/ui/lib/icons";
import SubscriptionHistoryList from "../../Elements/SubscriptionHistoryList";
import Separator from "../../Elements/Separator";
import { Button } from "../../Elements/Button";
import { sendAdobeEvent } from "../../../utils";

const History = ({ connectedUser, locale, t, theme, transactionslink }) => {
  const { subscriptionProducts } = connectedUser;

  const lastSubscriptions =
    subscriptionProducts.length > 0
      ? Array.prototype.slice.call(subscriptionProducts).sort((a, b) => {
          const aDate = a.startedAt || a.date;
          const bDate = b.startedAt || b.date;
          return moment(bDate)
            .utc()
            .diff(moment(aDate).utc());
        })
      : [];

  return (
    lastSubscriptions.length > 0 && (
      <Container
        backgroundColor={theme.colors.background.secondary}
        textAlign={"center"}
        flex={{
          default: "0 0 100%",
          tabletLarge: "0 0 48%"
        }}
        marginLeft={"auto"}
      >
        <Container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          minHeight={"100%"}
          padding={{
            default: "1.25rem",
            tablet: "1.375rem 1.875rem"
          }}
        >
          <Text.Heading bold secondary>
            {t("subscriptionHistory.title")}
          </Text.Heading>
          <Separator backgroundColor={theme.colors.background.secondary} />
          <SubscriptionHistoryList items={lastSubscriptions} />
          <Button
            forwardedAs={"a"}
            href={`/${locale}${transactionslink}`}
            minWidth={"11.25rem"}
            onClick={() =>
              sendAdobeEvent({
                location: "purchase history",
                category: "action",
                action: "view more"
              })
            }
          >
            <Container>{t("subscriptionHistory.viewMore")}</Container>
            <Icon icon={IbexVolume} color="currentColor" />
          </Button>
        </Container>
      </Container>
    )
  );
};

const mapStateToProps = state => ({
  connectedUser: state.user.connectedUser
});

export default connect(mapStateToProps)(withTranslation()(withTheme(History)));
