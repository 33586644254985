import PropTypes from "prop-types";

export const CustomAppPropTypes = {
  authenticated: PropTypes.bool.isRequired,
  transactionslink: PropTypes.string.isRequired,
  storeurl: PropTypes.string.isRequired,
  ibexproductid: PropTypes.string.isRequired,
  env: PropTypes.string
};

export const CustomAppDefaultProps = {
  authenticated: false,
  env: "UAT"
};
