import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { withTheme } from "styled-components";
import { getSubscriptionDescription } from "../../../utils";

import { Container, Text } from "@mooncake/ui";
import { DateContainer, List, Item } from "./styles";

const SubscriptionHistoryList = ({ items, t, theme }) => {
  const itemsList = items.slice(0, 3).map((transaction, indexTransaction) => (
    <Item key={indexTransaction}>
      <Text.Paragraph primary margin={"0"}>
        {moment(transaction.startedAt || transaction.date)
          .utc()
          .format("L")}
      </Text.Paragraph>
      <Container
        color={theme.colors.text.secondary}
        fontSize={{
          default: "1.2rem",
          tablet: "1.5rem"
        }}
        margin={{
          default: "0 0.625rem",
          tablet: "0 1.25rem"
        }}
      >
        •
      </Container>
      <Text.Paragraph
        primary
        flex={"1"}
        margin={"0"}
        dangerouslySetInnerHTML={{
          __html: getSubscriptionDescription(transaction, t)
        }}
      />
    </Item>
  ));

  return (
    <Container textAlign={"left"}>
      <DateContainer>{t("subscriptionHistory.date")}</DateContainer>
      <List>{itemsList}</List>
    </Container>
  );
};

const mapStateToProps = state => ({
  connectedUser: state.user.connectedUser
});

export default connect(mapStateToProps)(
  withTranslation()(withTheme(SubscriptionHistoryList))
);
