export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "Je abonnement is actief"
      },
      youCantPlay: {
        title: "Je abonnement is inactief.",
        description:
          "Je kunt het hieronder opnieuw activeren als je weer wilt spelen."
      },
      youDontHaveASubscription: {
        title: "Je hebt nog geen abonnement",
        joinNow: {
          title: "Krijg toegang tot de volledige ervaring",
          subscribe: "Abonneren"
        }
      }
    },
    yourSubscriptions: {
      title: "Abonnementen",
      description:
        "Bekijk hier je actieve en eerdere abonnementen op Rocksmith+.",
      nextBillingDate: "Volgende factuurdatum:",
      manage: "Beheren",
      cancel: "Annuleren",
      userStatus: {
        active: "Actief",
        inactive: "Inactief",
        suspended: "Inactief"
      }
    },
    subscriptionHistory: {
      title: "Abonnements geschiedenis",
      date: "Datum",
      viewMore: "Meer bekijken"
    },
    notLoggedIn: {
      title: "Geen toegang",
      message: "Je moet ingelogd zijn om deze pagina te openen."
    },
    ghostItems: {
      "PC-Monthly": "Abonnement van 1 maand (pc)",
      "PC-3Months": "Abonnement van 3 maanden (pc)",
      "PC-6Months": "Abonnement van 6 maanden (pc)",
      "PC-Annually": "Abonnement van 12 maanden (pc)",
      "PS-Monthly": "Abonnement van 1 maand (Playstation)",
      "PS-3Months": "Abonnement van 3 maanden (Playstation)",
      "PS-6Months": "Abonnement van 6 maanden (Playstation)",
      "PS-Annually": "Abonnement van 12 maanden (Playstation)",
      "XBOX-Monthly": "Abonnement van 1 maand (XBOX)",
      "XBOX-3Months": "Abonnement van 3 maanden (XBOX)",
      "XBOX-6Months": "Abonnement van 6 maanden (XBOX)",
      "XBOX-Annually": "Abonnement van 12 maanden (XBOX)",
      "iOS-Monthly": "Abonnement van 1 maand (iOS)",
      "iOS-3Months": "Abonnement van 3 maanden (iOS)",
      "iOS-6Months": "Abonnement van 6 maanden (iOS)",
      "iOS-Annually": "Abonnement van 12 maanden (iOS)",
      "ANDROID-Monthly": "Abonnement van 1 maand (Android)",
      "ANDROID-3Months": "Abonnement van 3 maanden (Android)",
      "ANDROID-6Months": "Abonnement van 6 maanden (Android)",
      "ANDROID-Annually": "Abonnement van 12 maanden (Android)"
    }
  }
};
