export default {
  translations: {
    profileStatus: {
      youCanPlay: {
        title: "지금 플레이할 수 있습니다"
      },
      youCantPlay: {
        title: "플레이할 수 없습니다",
        description: `Rocksmith+를 다시 즐겨보고 싶지 않으세요?
구독을 다시 활성화하면 바로 플레이할 수 있습니다!`
      },
      youDontHaveASubscription: {
        title: "아직 구독 플랜에 가입하지 않았습니다",
        joinNow: {
          title: "지금 가입해보세요",
          subscribe: "구독하기"
        }
      }
    },
    yourSubscriptions: {
      title: "구독 내역",
      description: "이곳에서 Rocksmith+ 구독 기록을 볼 수 있습니다",
      nextBillingDate: "다음 결제일: DD/MM/YYYY",
      manage: "관리",
      cancel: "취소",
      userStatus: {
        active: "활성화됨",
        inactive: "비활성화됨",
        suspended: "비활성화됨"
      }
    },
    subscriptionHistory: {
      title: "구독 내역",
      date: "날짜",
      viewMore: "더 보기"
    },
    notLoggedIn: {
      title: "인증 필요",
      message: "이 페이지를 보려면 로그인 해야 합니다!"
    },
    ghostItems: {
      "PC-Monthly": "1개월 구독 (PC) ",
      "PC-3Months": "3개월 구독 (PC) ",
      "PC-6Months": "6개월 구독 (PC) ",
      "PC-Annually": "12개월 구독 (PC) ",
      "PS-Monthly": "1개월 구독 (Playstation)",
      "PS-3Months": "3개월 구독 (Playstation)",
      "PS-6Months": "6개월 구독 (Playstation)",
      "PS-Annually": "12개월 구독 (Playstation)",
      "XBOX-Monthly": "1개월 구독 (XBOX)",
      "XBOX-3Months": "3개월 구독 (XBOX)",
      "XBOX-6Months": "6개월 구독 (XBOX)",
      "XBOX-Annually": "12개월 구독 (XBOX)",
      "iOS-Monthly": "1개월 구독 (iOS)",
      "iOS-3Months": "3개월 구독 (iOS)",
      "iOS-6Months": "6개월 구독 (iOS)",
      "iOS-Annually": "12개월 구독 (iOS)",
      "ANDROID-Monthly": "1개월 구독 (Android)",
      "ANDROID-3Months": "3개월 구독 (Android)",
      "ANDROID-6Months": "6개월 구독 (Android)",
      "ANDROID-Annually": "12개월 구독 (Android)"
    }
  }
};
